import request from '@/api/axios';

// oss临时token
// eslint-disable-next-line import/prefer-default-export
export function getoss() {
  return request({
    url: '/third/sts/l_getStsToken',
    method: 'get',
  });
}
// 视频列表查询
export function vList(query) {
  return request({
    url: '/param/video/l_list',
    method: 'post',
    data: query,
  });
}
// 下架视频
export function offVideo(query) {
  return request({
    url: '/param/video/l_updateStatus',
    method: 'post',
    data: query,
  });
}
// 拖拽
export function dragList(query) {
  return request({
    url: '/param/video/l_updateSort',
    method: 'post',
    data: query,
  });
}
// 发布视频
export function addVideo(query) {
  return request({
    url: '/param/video/l_addOrModi',
    method: 'post',
    data: query,
  });
}
// 增加播放量
export function addPlayNum(id) {
  return request({
    url: `/param/video/l_addPlayNum?pkid=${id}`,
    method: 'get',
  });
}
// 发布视频
export function isshowadd(id) {
  return request({
    url: `/param/video/l_isCanPush?nbrandId=${id}`,
    method: 'post',
  });
}
