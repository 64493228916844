<template>
  <div
    v-if="pop"
    v-cloak
    class="pop"
    @mousewheel.prevent
  >
    <div class="mask" />
    <div
      class="popMain"
      :style="{width: popWid + 'px' }"
    >
      <i
        v-show="closeImg"
        class="iconfont iconguanbidanchuang popClose"
        @click="close"
      />
      <div
        class="popTitlBox"
        :style="{marginTop: icon>0?27:53 + 'px' }"
      >
        <img
          v-if="icon == 0"
          src="../../assets/images/common/popImg.png"
          alt="popWarn"
          class="popWarn"
        >
        <img
          v-if="icon == 1"
          src="../../assets/images/common/yes.png"
          alt="popWarn"
          class="popWarn"
        >
        <p
          id="xldemo1"
          class="popTitl"
          :class="{xl_leftAlign:widthT>400?true:false}"
        >
          {{ title }}
        </p>
        <p class="xl_leftAlign popTitl">
          {{ leftTitle }}
        </p>
      </div>
      <slot name="content" />

      <div class="popBtn_warm_pop popBtn">
        <slot name="btn" />

        <z-button
          v-show="isCancle"
          type="info"
          class="cancle_warm_pop"
          @click="close"
        >
          取消
        </z-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WarmPop',
  props: {
    pop: { // 弹窗的显示和隐藏
      type: Boolean,
      default: false,
    },
    isCancle: { // 是否需要取消按钮
      type: Boolean,
      default: false,
    },
    closeImg: { // 是否需要关闭按钮
      type: Boolean,
      default: false,
    },
    icon: { // 0警告图标,1确定图标,2消息图标,-1不显示图标
      type: Number,
      default: -1,
    },
    popWid: { // 弹窗内容的宽度
      type: Number,
    },
    title: { // 居中的标题,用于小弹窗
      type: String,
      default: '',
    },
    leftTitle: { // 左对齐的标题,用于换行的标题
      type: String,
      default: '',
    },
  },
  data() {
    return {
      widthT: '',
    };
  },
  watch: {
    //   refundOrderNumber() {
    //     this.loadInfo()
    //   },
    pop(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.widthT = document.getElementById('xldemo1').offsetWidth;
        });
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
* {
    padding: 0;
    margin: 0;
    list-style: none;
}

.pop {}

.mask {
    position: fixed;
    width: 100%;
    height: 100%;
    background: red;
    z-index: 996;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
}

.popMain {
    z-index: 997;
    background-color: #ffffff;
    border-radius: 4px;
    position: fixed;
    top: 50%;
    left: 51%;
    transform: translateX(-51%) translateY(-50%);
    padding-right: 58px;
    padding-left: 58px;
    padding-bottom: 50px;
    min-width: 310px;
}

.popClose {
    // width: 14px;
    // height: 14px;
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 997;
    cursor: pointer;
    font-size: 14px;
    color: #333333;
}
.popClose:hover{
    color: #28cccc;
}

.popTitlBox {
    width: 100%;
    text-align: center;
    margin-top: 29px;
}

.popWarn {
    width: 63px;
    height: 63px;
    margin-bottom: 12px;
}

.popTitl {
    font-size: 20px;
    color: #333333;
    font-weight: bold;
    /* margin-top: 12px; */
    line-height: 27px;

    word-break: break-all;
    text-align: center;
}
.xl_leftAlign{
    max-width: 400px;
    text-align: left !important;
}
.popBtn_warm_pop {
    width: 100%;
    text-align: center;
    margin-top: 38px;
}

.popBtn_warm_pop button {
    /* width: 94px;
    height: 32px;
    display: inline-block;
    border-radius: 4px;
    border: solid 1px #19b0b0;
    font-size: 14px;
    color: #19b0b0; */
    margin: 0 26px;
    /* line-height: 32px;
    cursor: pointer; */
}

.popBtn div:hover {
    background-image: linear-gradient(-22deg, #28cccc 0%, #73e2e2 100%);
    color: #fff;
    border: solid 1px #fff;
}
.popBtn div a:hover{
    color: #fff;
}
.xl_cancle_btn{
    border:1px solid rgba(229,229,229,1) !important;
    color:rgba(153,153,153,1) !important;
}
.xl_cancle_btn:hover{
    border:1px solid rgba(229,229,229,1) !important;
    color:rgba(153,153,153,1) !important;
    background-image: none !important;
    background-color: none !important;
}
</style>
