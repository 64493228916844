<template>
  <div v-if="copyText">
    <!--<Tooltip placement="top" transfer class="wer">-->
    <!--{{copyText}}-->
    <!--<div slot="content">-->
    <!--{{copyText}}-->
    <!--<div class="btnCopy" @click="copy">复制</div>-->
    <!--</div>-->
    <!--</Tooltip>-->
    <el-tooltip
      placement="top"
      :hide-after="time"
      :popper-class="customizeCls ? 'copyTextPanel customizeCls' : 'copyTextPanel' "
    >
      <div slot="content">
        <span
          :class="{'new_style':isEllipsis}"
          style="line-height: 35px;"
        >{{ copyText }}</span>
        <div
          class="btnCopy"
          @click="copy"
        >
          复制
        </div>
      </div>
      <div>{{ showText || copyText }}</div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'CopyText',
  props: {
    copyText: {
      type: String,
      value: '',
    },
    showText: {
      type: String,
      value: '',
    },
    customizeCls: {
      type: Boolean,
      default: false,
    },
    isEllipsis: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      time: 600000,
    };
  },
  methods: {
    copy() {
      const oInput = document.createElement('input');
      oInput.value = this.copyText;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand('Copy');
      oInput.style.display = 'none';
      this.$message.closeAll();
      this.$message({
        message: '复制成功',
        type: 'success',
        duration: 1300,
      });
    },
  },
};
</script>

<style lang="scss">
    .deadDetail {
      display:none;
      position: absolute;
      line-height: 4px;
      left: -5%;
      top: -27px;
      box-shadow: 0 0 10px rgba(0,0,0,.2);
      color: #000;
      font-size: 12px;
      text-align: center;
      box-sizing: border-box;
      z-index: 2;
      transition:.2s;
        .mod {
          position: relative;
          min-width: 142px;
          color: white;
          padding: 3px 64px 3px 11px;
          height: 33px;
          z-index: 2;
          box-sizing: border-box;
          background: rgba(0,0,0,.6);
          p{
            line-height: 26px;
          }
        }
        .btnCopy {
          position: absolute;
          right: 0;
          background: #28CCCD;
          top: 0px;
          color: white;
          padding: 14.6px 15px;
          cursor: pointer;
        }
        .mod:before, .deadDetail .mod:after {
          position: absolute;
          content: '';
          top: 33px;
          transform: rotate(180deg);
          left: 50%;
          margin-left: -6px;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid rgba(0,0,0,.5);
          z-index: 2;
         }
  }
  .new_style{
    display: inline-block !important;
    max-width: 500px !important;
    max-height: 500px !important;
    overflow: auto !important;
    text-overflow: 	clip !important;
    white-space: normal !important;
    word-break: break-all;
  }
  .new_style + .btnCopy{
    display: flex;
    /*实现垂直居中*/
    align-items: center;
    /*实现水平居中*/
    justify-content: center;
  }
</style>
